function SessionRedirectService () {
    'use strict';

    var doRedirect = function doRedirect($scope, $window, redirectUrl) {
        $window.location.href = redirectUrl;
    };

    return {
        redirectTo: doRedirect
    };

}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.factory('SessionRedirectService', SessionRedirectService);
};