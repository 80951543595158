function LockdownEnforcement($cookies, $q, $location, $rootScope, Lockdown, LockdownEnforcementBypassCookie,
                             LockdownPath, LimitedLockdown, Persistence, $window) {
    var $scope = $rootScope.$new();

    /**
     * TP-303559
     *
     * Add support for a new URL https://test.mapnwea.org/#/nopopup
     *
     * When accessing the new URL the Test Player should load in the same
     * browser window.
     *
     * Fetching the url and validate if the url pathname contains nopopup
     * then persist the 'nopopup' value as true so that once
     * SessionRedirect.redirectToSessionLogin() comes from
     * assessment-frontend with root pathparam('/'), we can identify its
     * nopopup url and load test player on same window.
     *
     * @param {Function} load - A function that returns a possible value (the
     *      string 'true', 'false', or value null/undefined). This can be
     *      acquired from Persistence.load.bind(Persistence, namespace, key).
     * @param {Function} save - A function that takes a value as a string to
     *      store. The can be acquired from Persistence.save.bind(Persistence,
     *      namespace, key).
     * @param {Function} clear - A function that clears a value that has been
     *      stored. This can be acquired from
     *      Persristence.clear.bind(Persistence).
     * @param {string} testPlayerUrlLoadedWith - The full path of the browser. Comes
     *      from $location.href.
     * @returns {boolean} - popup or nopopup flag.
     */
    this.isNoPopupMode = (
        load,
        save,
        clear,
        testPlayerUrlLoadedWith
    ) => {

        const isTestPlayerLoadedWithNoPopup = load();
        // checking if url is for nopopup then maintain in variable 'isNoPopupTestPlayerUrl'
        const isNoPopupTestPlayerUrl = _.endsWith(testPlayerUrlLoadedWith,'/nopopup');

        if (isNoPopupTestPlayerUrl) {
            // checking if its nopopup test player url then persist nopopup value as true throughout the session
            save('true');
        }

        if (isTestPlayerLoadedWithNoPopup || isNoPopupTestPlayerUrl) {
            // checking if nopopup test player url OR its persist value true then load in same window
            return true;
        } else {
            // clear persist value only if user tries to load on same window with different pathname
            clear();
            return false;
        }
    };

    /**
     * Determine if the user is using either form of lock down.
     * Route them to limited lock down launcher if no, or the root path if yes.
     * Prevent the former from visiting the launcher path; prevent the latter from leaving the launcher path.
     * @method enforce
     * @returns {boolean} - True if enforcement was necessary, false otherwise
     */
    this.enforce = function () {
        Lockdown.persistLDBKeys();

        var bypassCookiePresent = $cookies.get(LockdownEnforcementBypassCookie) === 'true';
        var eitherLockdownPresent = Lockdown.isCurrentWindowLockdown() || Lockdown.isLDBStatusPersisted() || LimitedLockdown.isCurrentWindowLimitedLockdown();

        const namespace = 'TPSL';
        const key = 'nopopup';
        const enforcementRequired = !eitherLockdownPresent &&
            !bypassCookiePresent &&
            !this.isNoPopupMode(
                Persistence.load.bind(Persistence, namespace, key),
                Persistence.save.bind(Persistence, namespace, key),
                Persistence.clear.bind(Persistence, namespace, key),
                $location.path() // to support #
            );
        const resolvedPath = enforcementRequired ? LockdownPath : '/';

        $scope.$on('$routeChangeStart', function (event, next, current) {
            /* istanbul ignore else */
            if ((current && current.$$route && current.$$route.originalPath === LockdownPath && !this.eitherLockdownPresent) ||
                (next && next.$$route && next.$$route.originalPath === LockdownPath && this.eitherLockdownPresent)) {
                event.preventDefault();
            }
        }.bind({eitherLockdownPresent: eitherLockdownPresent}));
        $location.path(resolvedPath);
        return enforcementRequired;
    };
}

const bypassCookieName = 'workstationReadinessBypass';

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.service('LockdownEnforcement', LockdownEnforcement);
    app.constant('LockdownEnforcementBypassCookie', bypassCookieName);
};
