function AuthApi ($q, $http, AppConfig) {
    'use strict';

    var basePath = AppConfig.nweaAPIHost;

    var routes = {
        testSessionAuthentication:  basePath + '/auth/v1/test-sessions'
    };

    /**
     * @method testSessionAuthentication
     * @param   {string}      sessionName
     * @param   {string}      sessionPin
     * @returns {Promise.<BID|string>} - Promise of a testSessionBid on success, or an error string on failure.
     */
    var testSessionAuthentication = function (sessionName, sessionPin) {
        var request = {
            method: 'POST',
            url:    routes.testSessionAuthentication,
            data:   {
                username: sessionName,
                password: sessionPin
            }
        };

        return $http(request).then(
            function (response) {
                return response.data;
            },
            function (errorResponse) {
                return $q.reject(errorResponse);
            }
        );
    };

    return {
        testSessionAuthentication: testSessionAuthentication
    };
}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.factory('AuthApi', AuthApi);
};