var testPlayerSessionKeys = {
    schoolBid:      'schoolBid',
    sessionName:    'sessionName',
    sessionPIN:     'sessionPIN',
    testPlayerType: 'testPlayerType',
    testSessionBid: 'testSessionBid'
};

function TestPlayerSessionService (Persistence, TestPlayerSessionKeys) {
    'use strict';

    var namespace = 'test_player_session_login';

    return {
        getSchoolBid: function () {
            return Persistence.load(namespace, TestPlayerSessionKeys.schoolBid);
        },
        setSchoolBid: function (schoolBid) {
            Persistence.save(namespace, TestPlayerSessionKeys.schoolBid, schoolBid);
        },
        getSessionName: function () {
            return Persistence.load(namespace, TestPlayerSessionKeys.sessionName);
        },
        setSessionName: function (setSessionName) {
            Persistence.save(namespace, TestPlayerSessionKeys.sessionName, setSessionName);
        },
        getSessionPIN: function () {
            return Persistence.load(namespace, TestPlayerSessionKeys.sessionPIN);
        },
        setSessionPIN: function (sessionPIN) {
            Persistence.save(namespace, TestPlayerSessionKeys.sessionPIN, sessionPIN);
        },
        getTestPlayerType: function () {
            return Persistence.load(namespace, TestPlayerSessionKeys.testPlayerType);
        },
        setTestPlayerType: function (testPlayerType) {
            Persistence.save(namespace, TestPlayerSessionKeys.testPlayerType, testPlayerType);
        },
        getTestSessionBid: function () {
            return Persistence.load(namespace, TestPlayerSessionKeys.testSessionBid);
        },
        setTestSessionBid: function (testSessionBid) {
            Persistence.save(namespace, TestPlayerSessionKeys.testSessionBid, testSessionBid);
        },
        removeSession: function() {
            Persistence.clearAll(namespace);
        }
    };
}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.constant('TestPlayerSessionKeys', testPlayerSessionKeys);
    app.factory('TestPlayerSessionService', TestPlayerSessionService);
};