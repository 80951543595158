/**
 * @typedef {string} TestPlayerTypeEnum
 */

/**
 * @type {{STATE: TestPlayerTypeEnum, MAPGROWTH: TestPlayerTypeEnum, FREE: TestPlayerTypeEnum}}
 */
var enums = {
    STATE:      "State",
    MAPGROWTH:  "Map Growth",
    FREE:       "Free"
};

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.constant('TestPlayerTypeEnum', enums);
};