var windowName = 'Test Player Limited Lockdown';

function LimitedLockdown ($window, AppConfig, LimitedLockdownWindowName, NewRelic) {
    var launchTarget = $window.location.protocol + '//' + AppConfig.frontEndHost;
    var windowFeatures = "menubar=no,toolbar=no,location=no,status=no,resizable=no,scrollbars=yes," +
        "directories=no,personalbar=no,channelmode=yes,dialog=no,minimizable=no,fullscreen=yes," +
        "height=" + $window.screen.height + ",width=" + $window.screen.width;

    /**
     * Launch a new window under Limited Lockdown specification and direct it to the app root
     * @method  launch
     * @returns {Object} A window object with a DOM (https://developer.mozilla.org/en-US/docs/Web/API/Window)
     */
    this.launch = function () {
        var lockdownWindow = $window.open(launchTarget, LimitedLockdownWindowName, windowFeatures);

        //NewRelic noticeError for test player pop-up blocking issue
        if (!lockdownWindow) {
            NewRelic.noticeError(new Error('Test Player Pop-Up failed to open'), 'limited-lockdown.service', 'LimitedLockdown', {
                message: 'Test Palyer Pop-Up failed to open as the Pop-Up option disabled in Browser Settings',
                failureType: 'limitedLockDownFailedOpeningTestPlayerPopUp'
            });
        }

        /* istanbul ignore else */
        if (lockdownWindow) {
            lockdownWindow.onload = function () {
                this.resizeTo($window.screen.availWidth,$window.screen.availHeight);
            }.bind(lockdownWindow);
            lockdownWindow.NWEA = {};
        }

        return lockdownWindow;
    };

    /**
     * @method  isCurrentWindowLimitedLockdown
     * @returns {boolean}
     */
    this.isCurrentWindowLimitedLockdown = function () {
        return $window.name === LimitedLockdownWindowName;
    };
}

/**
 * @param {Object} app - Angular application module
 */
module.exports = function (app) {
    app.constant('LimitedLockdownWindowName', windowName);
    app.service('LimitedLockdown', LimitedLockdown);
};