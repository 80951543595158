var Strings = {
    login: 'Log in',
    invalidLogin: 'Unable to log in.',
    invalidLoginReason: 'Please enter the correct Session Name and Password.',
    nextButton: 'Next',
    howToDisablePopupBlockingUrl: 'https://nwea.force.com/nweaconnection/s/article/Oops-Where-is-my-test-window-1405102090050',
    practiceTestUrl: 'https://practice.mapnwea.org/#/practice-landing',
    tryPracticeTest: 'Try the Practice Test',
    tryPracticeTestSpanish: 'Practica antes de comenzar la prueba.',
    studentResources: 'Student Resources',
    studentResourcesSpanish: 'Recursos estudiantiles',
    sessionName: 'Session Name',
    sessionNameSpanish: 'Nombre de sesión',
    sessionPwd: 'Session Password',
    sessionPwdSpanish: 'Contraseña de sesión',
    joinBtn: 'Please enter the Session Name and Session Password',
    verbiage: {
        clickToBegin:   "Click here to select or begin typing.",
        selectAStudent: "Select your name above to continue.",
        joinSessionBtn: {
            disabled : "Please enter the Session Name and Session Password",
            enabled : "Continue"
        }
    },
    placeholder: 'PLACEHOLDER TEXT'
};

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.constant('SessionsStrings', Strings);
};
