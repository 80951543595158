function ChromeKioskAppID () {
    var idsPerEnvironment = {
        production: 'omkghcboodpimaoimdkmigofhjcpmpeb',
        int03:      'olaaocfpicpjiocmoklnbfpdlbglbadp',
        team05:     'eagiabcjmmnobaabfobkepolchcfjefb'
    };

    var urlMatchersPerEnvironment  = {
        production: /test\.mapnwea\.org/,
        int03:      /int03\.mapnwea\.org/,
        team05:     /team05\.map2\.nweacolo\.pvt/
    };

    /**
     * @param {string} host
     * @returns {string}
     */
    this.get = function (host) {
        if (urlMatchersPerEnvironment.production.exec(host)) {
            return idsPerEnvironment.production;
        } else if (urlMatchersPerEnvironment.int03.exec(host)) {
            return idsPerEnvironment.int03;
        } else if (urlMatchersPerEnvironment.team05.exec(host)) {
            return idsPerEnvironment.team05;
        }

        return '';
    };
}

module.exports = function (app) {
    app.constant('ChromeKioskApp', {
        exitMessage: 'exit-app'
    });
    app.service('ChromeKioskAppID', ChromeKioskAppID);
};