/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.service('TestPlayerPaths', function ($window, AppConfig, SessionQueryParams) {
        this.summative = function () {
            return AppConfig.stateTestPlayerRedirect + SessionQueryParams.getSummativeURLWithParams();
        };

        this.growth = function () {
            return $window.location.protocol + '//' + AppConfig.frontEndHost + '/growth/';
        }

        this.practice = function () {
            return $window.location.protocol + '//' + AppConfig.frontEndHost + '/practice/';
        }

        this.default = function () {
            return $window.location.protocol + '//' + AppConfig.frontEndHost + '/';
        }
    });
};