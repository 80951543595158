require('./sessions.css');
const uuidv4 = require('uuid/v4')

function SessionsRoute ($routeProvider) {
    $routeProvider.when('/', {
        template: require('./sessions.partial.html'),
        controller: 'SessionsController'
    });

    // Make it the default route
    $routeProvider.otherwise('/');
}

function SessionsController (
    $scope,
    $window,
    $q,
    AppConfig,
    ProctorApi,
    AuthApi,
    TestPlayerSessionService,
    SessionsStrings,
    EnterKeyCode,
    SessionRedirectService,
    TestPlayerTypeEnum,
    TestPlayerPaths,
    OS,
    $location,
    Persistence,
    NewRelic,
    DebugSessionUUID,
    UserAgentClientHints
) {
    /**
     * wsCheck value getting load from assessment-frontend(workstation-report.ctrl.js)
     * As per requirement when the student hits the testplayer url for the first time the request must
     * redirect to workstationreadiness check
     * In order to avoid WRC check for multiple time we use SessionStorage
     * wsCheck:true load testplayer login page
     * wsCheck:undefined redirects to workstation report.
     */
    $scope.init = () =>  {

        if ( UserAgentClientHints.clientHintsExists() ) {
            var clientHintsPromise = UserAgentClientHints.clientHintsData(['platform', 'platformVersion','fullVersionList']);
            clientHintsPromise.then( (userAgentData) => {
                    Persistence.save('TPSL', 'ClientHintsHighValues', userAgentData);
                    setTimeout(() => {
                        WRCCheck();
                    }, 1);
                });
        } else {
            WRCCheck();
        }
    }

    function WRCCheck() {
        const wsCheck = Persistence.load('TPSL', 'wsCheck');

        if (!wsCheck) {
            //Service without parenthesis will be returned as function, and with parenthesis will be returned as value
            let url = TestPlayerPaths.growth();
            url += '#/workstation-report';
            SessionRedirectService.redirectTo($scope, $window, url);
        } else {
            Persistence.clear('TPSL', 'wsCheck');
        }
    }

    $scope.form     = null;
    $scope.session  = {
        name: null,
        key: null,
        invalidLogin: false
    };
    $scope.strings  = SessionsStrings;
    $scope.enter    = EnterKeyCode;
    $scope.isIpad   = OS.isIpad();
    $scope.inflight = false;

    var host     = $location.host();
    var port     = $location.port() ? ':' + $location.port() : '';
    var protocol = $location.protocol();

    $scope.practiceTestUrl = SessionsStrings.practiceTestUrl +
        '?ref=' + $window.encodeURIComponent(protocol + '://' + host + port);
    $scope.studentResourcesUrl = AppConfig.studentResourcesUrl +
        '?ref=' + $window.encodeURIComponent(protocol + '://' + host + port);

    /**
     * @method stateSessionsSuccess
     * @private
     * @param {BID} testSessionBid
     */
    function stateSessionsSuccess (response) {
        TestPlayerSessionService.setSchoolBid(response.schoolBid);
        TestPlayerSessionService.setTestSessionBid(response.testSessionBid);
        TestPlayerSessionService.setTestPlayerType(TestPlayerTypeEnum.STATE);
        TestPlayerSessionService.setSessionName($scope.session.name);
        TestPlayerSessionService.setSessionPIN($scope.session.key);
        SessionRedirectService.redirectTo($scope, $window, TestPlayerPaths.summative());
    }

    function stateSessionsFailure (error) {
        //TODO placeholder until login api has been defined and returns correct status (204) for invalid input
        $scope.session.invalidLogin = true;
        $('#session-id-standard').trigger('focus');
        $scope.inflight = false;
    }

    function joinTestSessionSuccess (response) {
        if (response.data.isLoginSuccessful) {
            var joinTestSessionSuccessAttributes = {};
            // Set cookies and redirect to map growth TP
            TestPlayerSessionService.setTestSessionBid(_.get(response, 'data.clientTestSessionDo.testSessionId'));
            TestPlayerSessionService.setTestPlayerType(TestPlayerTypeEnum.MAPGROWTH);
            TestPlayerSessionService.setSessionName($scope.session.name);
            TestPlayerSessionService.setSessionPIN($scope.session.key);
            SessionRedirectService.redirectTo($scope, $window, TestPlayerPaths.growth());

            joinTestSessionSuccessAttributes.session_name = $scope.session.name;
            joinTestSessionSuccessAttributes.testPlayerType = TestPlayerTypeEnum.MAPGROWTH;
            joinTestSessionSuccessAttributes.testSessionBid = response.data.clientTestSessionDo.testSessionId;
            joinTestSessionSuccessAttributes.partnerBId = response.data.clientTestSessionDo.partnerBid;
            joinTestSessionSuccessAttributes.methodName = 'joinTestSessionSuccess';
            joinTestSessionSuccessAttributes.moduleName = 'sessions.controller.js';
            joinTestSessionSuccessAttributes.message = 'joinTestSession successful';

            NewRelic.addPageAction('joinTestSession_success', joinTestSessionSuccessAttributes);
        } else {
            AuthApi.testSessionAuthentication($scope.session.name, $scope.session.key)
                .then(stateSessionsSuccess, stateSessionsFailure);
        }
    }

    function joinTestSessionFailure (response) {
        var joinTestSessionErrorAttributes = {
            sessionName: $scope.session.name,
            errorMsg: response.data.errorMessage,
            uniqueBrowserSession: DebugSessionUUID,
            failureType: 'joinTestSessionFailedInvalidLoginParam',
            message: 'JoinTestSession failed'
        };
        NewRelic.noticeError('joinTestSession_failure', 'sessions.controller.js', 'joinTestSessionFailure', joinTestSessionErrorAttributes);
        AuthApi.testSessionAuthentication($scope.session.name, $scope.session.key)
            .then(stateSessionsSuccess, stateSessionsFailure);
    }

    $scope.joinTestSession = function () {
        /* istanbul ignore else  */
        if ($scope.form.$valid) {
            $scope.inflight = true;
            $scope.session.invalidLogin = false;
            NewRelic.setCustomEventAttribute('sessionName', $scope.session.name);
            ProctorApi.joinTestSession($scope.session.name, $scope.session.key,
                joinTestSessionSuccess,
                joinTestSessionFailure);
        }
    };
}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.config(SessionsRoute);
    app.controller('SessionsController', SessionsController);
    app.constant('EnterKeyCode', 13);
    app.constant('DebugSessionUUID', uuidv4());
};
