require('./limited-lockdown.css');

var lockdownPath = '/lockdown';

function LimitedLockdownRoute ($routeProvider, LockdownPath) {
    $routeProvider.when(LockdownPath, {
        template: require('./limited-lockdown.partial.html'),
        controller: 'LimitedLockdownController'
    });
}

function LimitedLockdownController($scope, LimitedLockdown, OS, SessionsStrings, $window, $location, AppConfig,
    TestPlayerPaths) {
    LimitedLockdown.launch();
    $scope.isIpad = OS.isIpad();
    $scope.howToDisablePopupBlockingUrl = SessionsStrings.howToDisablePopupBlockingUrl;
    $scope.studentLogin = TestPlayerPaths.default();
}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.constant('LockdownPath', lockdownPath);
    app.config(LimitedLockdownRoute);
    app.controller('LimitedLockdownController', LimitedLockdownController);
};
