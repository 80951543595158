// Polyfill - Number.isInteger
Number.isInteger = Number.isInteger || function (value) {
    return typeof value === 'number' &&
        isFinite(value) &&
        Math.floor(value) === value;
};

import { NWEALowLevelHandler } from "cotija";

require('angular-cookies');
require('angular-route');
require('nwea-js-angular-persistence');

// Application-wide styles
require('../public/css/icons.css');
require('../public/css/common-styles.css');
require('../public/css/common-errors.css');

// Application-wide types for jsdoc
/**
 * @typedef {string} BID
 */

const app = angular.module(NG_MODULE_NAME, ['ngCookies', 'ngRoute', 'NWEA.angular.persistence']);

// Constants
require('./constants/test-player-type-enum.constant')(app);

// Non-feature-specific services
require('./api-wrappers/proctor-api.service')(app);
require('./api-wrappers/auth-api.service')(app);
require('./utilities/utilities.service')(app);
require('./services/session-redirect.service')(app);
require('./services/session-query-params.service')(app);
require('./services/test-player-session.service')(app);
require('./new-relic/new-relic.service')(app);

// Browser detection and manipulation services
require('./browser-detection/limited-lockdown.service')(app);
require('./browser-detection/lockdown.service')(app);

// Operating system detection
require('./os-detection/os.service')(app);
require('./os-detection/user-agent-client-hints.service')(app);

// Sessions feature
require('./sessions/test-player-paths.service.js')(app);
require('./sessions/sessions-strings.constant')(app);
require('./sessions/sessions.controller')(app);

// Limited Lockdown Feature
require('./limited-lockdown/limited-lockdown.controller')(app);
require('./limited-lockdown/lockdown-enforcement.service')(app);

// Legal footer directive
require('./legal-footer/legal-footer.directive')(app);

// Secure Browser Version directive
require('./secure-browser-version/secure-browser-version.directive')(app);

// Window close header directive
require('./window-close-header/chrome-kiosk-app-id.service')(app);
require('./window-close-header/window-close-header.directive')(app);

app.config(function ($locationProvider, $sceDelegateProvider, PersistenceProvider, AppConfig) {
    $locationProvider.hashPrefix('');
    $sceDelegateProvider.resourceUrlWhitelist(AppConfig.urlWhiteList);
    PersistenceProvider.useSessionStorage();
});

app.run(function (LockdownEnforcement, UtilitiesService) {
    const lowLevelHandler = new NWEALowLevelHandler();

    lowLevelHandler.setAppInitialized(true);
    lowLevelHandler.fadeLoader();

    /* istanbul ignore if */
    if (!LockdownEnforcement.enforce()) {
        $(document).on('keydown', UtilitiesService.tabKeyHandler);
    }
});

window.addEventListener('load', function () {
    angular.bootstrap(document.getElementById('ng-app'), [NG_MODULE_NAME]);
});