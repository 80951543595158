require('./secure-browser-version.css');

function SecureBrowserVersionDirective ($window,Lockdown) {
    'use strict';

    return {
        restrict: 'E',
        template: require('./secure-browser-version.partial.html'),
        scope: {},
        link: function (scope) {
            scope.isSecureBrowser = (Lockdown.isCurrentWindowLockdown() || Lockdown.isLDBStatusPersisted()) && $window.name !== 'chromeSecureTestingApp';
            scope.version = Lockdown.getLDBVersion() || Lockdown.getPersistedLDBVersion();
        }
    };
}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.directive('secureBrowserVersion', SecureBrowserVersionDirective);
};