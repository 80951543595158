/**
 * @typedef Object CustomAttributes
 * Arbitrary property-value map to provide additional information to newrelic
 * @type {Object.<string, string>}
 */

function NewRelic ($window, $log) {
    /**
     * Logs the provided error to newrelic along with any provided custom attributes,
     * then re-throws the error
     * @method logAndRethrow
     * @param {Error} error
     * @param {string} module
     * @param {string} method
     * @param {CustomAttributes} [customAttributes]
     */
    this.logAndRethrow = function (error, module, method, customAttributes) {
        customAttributes = customAttributes || {};
        customAttributes.module = module;
        customAttributes.method = method;
        if ($window.newrelic) {
            $window.newrelic.noticeError(error, customAttributes);
        }
        throw error;
    };

    /**
     * Captures a page action in NR.
     *
     * @public
     * @method addPageAction
     * @param {string} actionName name of the page action
     * @param {CustomAttributes} [customAttributes]
     */
    this.addPageAction = function (actionName, customAttributes) {
        if ($window.newrelic) {
            $window.newrelic.addPageAction(actionName, customAttributes);
        }
    };

    /**
     * Submits an error report to new relic
     * @method noticeError
     * @param {Error} error
     * @param {string} module
     * @param {string} [method]
     * @param {CustomAttributes} [customAttributes]
     */
    this.noticeError = function (error, module, method, customAttributes) {
        if ($window.newrelic) {
            customAttributes = customAttributes || {};
            customAttributes.module = module;
            customAttributes.method = method;
            $window.newrelic.noticeError(error, customAttributes);
        } else {
            $log.warn(error.message);
        }
    };

    /**
     * Sets the custom event attribute as a custom attribute to new relic
     * @param {string} customEventAttribubteKey
     * @param {object} customEventAttribubteValue
     */
    this.setCustomEventAttribute = function (customEventAttribubteKey, customEventAttribubteValue) {
        if ($window.newrelic) {
            $window.newrelic.setCustomAttribute(customEventAttribubteKey, customEventAttribubteValue);
        }
    };

}

module.exports = function (app) {
    app.service('NewRelic', NewRelic);
};
