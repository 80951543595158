var _ = require('lodash');

var PersistedLDBKeys = {
    namespace:  'PERSISTED_LDB',
    keys:       {
        status:  'STATUS',
        version: null
    }
};

var SecureBrowserCriteria = {
    chromeKiosk: {   // chrome kiosk
        display: 'Chrome Kiosk Secure Browser',
        versionSearch: 'NWEAChromeApp/',
        versionRegexp: /NWEAChromeApp\/(\d+(\.\d+)*)/
    },
    windows: {   // windows
        display: 'Windows Secure Browser',
        versionSearch: 'NWEALDB/',
        versionRegexp: /NWEALDB\//
    },
    iPad: {   // ipad
        display: 'iPad Secure Browser',
        versionSearch: 'NWEA Lockdown/',
        versionRegexp: /NWEA\sLockdown\//
    },
    mac: {   // mac
        display: 'Mac Secure Browser',
        versionSearch: 'SecureBrowser/',
        versionRegexp: /SecureBrowser\//
    }
};

function Lockdown ($window, PersistedLDBKeys, Persistence) {
    /**
     * @method  isCurrentWindowLockdown
     * @returns {boolean}
     */
    this.isCurrentWindowLockdown = function () {
        if ($window.navigator.userAgent.toLowerCase().indexOf('mac') >= 0) {
            return typeof($window.SecureBrowser) !== "undefined";
        }
        // iPad App sets $window.SecureBrowser
        else if ($window.navigator.userAgent.toLowerCase().indexOf('ipad') >= 0) {
            return typeof($window.SecureBrowser) !== "undefined";
        }
        else if ($window.navigator.userAgent.toLowerCase().indexOf('opera') >= 0) {
            // separate check as Opera get JS error for external variable used by $windows
            return false;
        } else if ($window.name === 'lockdownBrowser' || this.isChromiumKioskApp() || $window.name === 'chromeSecureTestingApp') {
            return true;
        } else {
            // $windows:
            return (('external' in $window) && ('IsSecure' in $window.external));
        }
    };

    /**
     * Persist LDB keys (status and version) which may no longer be returning reliable values.
     * This is due to how window.location.href changes / overwrites steps that the Lockdown browser may take to identify itself.
     * Required in order to properly identify if the user is still in Lockdown when redirecting from TP -> TPSL.
     * @method persistLDBKeys
     */
    this.persistLDBKeys = function () {
        if (this.isCurrentWindowLockdown()) {
            Persistence.save(PersistedLDBKeys.namespace, PersistedLDBKeys.keys.status, 1);

            var version = this.getLDBVersion();
            Persistence.save(PersistedLDBKeys.namespace, PersistedLDBKeys.keys.version, version);
        }
    };

    /**
     * Return the persisted truth value of isLDBStatusPersisted()
     * @method isLDBStatusPersisted
     * @returns {boolean}
     */
    this.isLDBStatusPersisted = function () {
        return Persistence.safeLoad(PersistedLDBKeys.namespace, PersistedLDBKeys.keys.status, null) === 1;
    };

    /**
     * Return the persisted value of getLDBVersion()
     * @method  getPersistedLDBVersion
     * @returns {string}
     */
    this.getPersistedLDBVersion = function () {
        return Persistence.safeLoad(PersistedLDBKeys.namespace, PersistedLDBKeys.keys.version, null);
    };

    /**
     * Return the current lockdown version
     * @method  getLDBVersion
     * @returns {string|null}
     */
    this.getLDBVersion = function () {
        var userAgent = $window.navigator.userAgent;
        var secureBrowserVersion = null;

        _.values(SecureBrowserCriteria).forEach(function(secureBrowserCriteria) {
            var versionSearch = secureBrowserCriteria.versionSearch;

            if (userAgent.indexOf(versionSearch) !== -1) {
                var versionRegex = /(\d+(\.\d+)*)/; // any number of integers separated by dots
                var version = versionRegex.exec(userAgent.substring(userAgent.indexOf(versionSearch) + versionSearch.length));

                secureBrowserVersion = version[1];
            }
        });
        return secureBrowserVersion;
    };

    /**
     * @method isChromiumKioskApp
     * @returns {boolean}
     */
    this.isChromiumKioskApp = function () {
        var userAgent = $window.navigator.userAgent;

        return userAgent.indexOf(SecureBrowserCriteria.chromeKiosk.versionSearch) !== -1;
    };

    this.getChromeKioskVersion = function () {
        var userAgent = $window.navigator.userAgent;

        if (this.isChromiumKioskApp()) {
            return SecureBrowserCriteria.chromeKiosk.versionRegexp.exec(userAgent)[1];
        } else {
            return null;
        }
    };

    /**
     * @method semverGTE
     * @param {string} a - a semver string formatted as XX.YY.ZZ
     * @param {string} b - a semver string formatted as XX.YY.ZZ
     * @return {boolean} true if a is >= b in order of XX, YY, ZZ (major, minor, bugfix)
     */
    this.semverGTE = function (a, b) {
        var semverRegex = /(\d+)\.?(\d+)?\.?(\d+)?/;

        var anum = [
            parseInt(semverRegex.exec(a)[1], 10),
            parseInt(semverRegex.exec(a)[2], 10),
            parseInt(semverRegex.exec(a)[3], 10)
        ];
        var bnum = [
            parseInt(semverRegex.exec(b)[1], 10),
            parseInt(semverRegex.exec(b)[2], 10),
            parseInt(semverRegex.exec(b)[3], 10)
        ];

        function gt (x, y) {
            return Number.isInteger(x) && !Number.isInteger(y) || x > y;
        }

        function eq (x, y) {
            return Number.isInteger(x) && !Number.isInteger(y) || x === y;
        }

        return gt(anum[0], bnum[0]) ||
            eq(anum[0], bnum[0]) && gt(anum[1], bnum[1]) ||
            eq(anum[0], bnum[0]) && eq(anum[1], bnum[1]) && gt(anum[2], bnum[2]) ||
            eq(anum[0], bnum[0]) && eq(anum[1], bnum[1]) && eq(anum[2], bnum[2]);
    };

}

/**
 * @param {Object} app - An AngularJS application module
 */
module.exports = function (app) {
    app.constant('PersistedLDBKeys', PersistedLDBKeys);
    app.constant('SecureBrowserCriteria', SecureBrowserCriteria);
    app.service('Lockdown', Lockdown);
};