require('./window-close-header.css');

function WindowCloseHeaderDirective ($window, ChromeKioskApp, ChromeKioskAppID, ChromeKioskMinVersionAsMobile, Lockdown, NewRelic, OS, Persistence) {
    'use strict';

    function hideCloseButtonFunction(scope) {
        if ((!(Lockdown.isCurrentWindowLockdown() || Lockdown.isLDBStatusPersisted()) && Persistence.load('TPSL', 'nopopup')) || $window.name === 'chromeSecureTestingApp') {
            return true;
        } else {
            return scope.isMobileApp();
        }
    }

    return {
        restrict: 'E',
        template: require('./window-close-header.partial.html'),
        link: function (scope, element, attrs) {
            /**
             * Checks whether the app is in a mobile environment.
             * @method isMobileApp
             * @returns {boolean}
             */
            scope.isMobileApp = function () {
                if (Lockdown.isChromiumKioskApp()) {
                    return !Lockdown.semverGTE(
                        Lockdown.getChromeKioskVersion(),
                        ChromeKioskMinVersionAsMobile
                    );
                } else {
                    return OS.isIpad();
                }
            };

            /**
             * This function decides if close button (Cross icon) needs to be displayed or not, on relevant pages.
             * Hide close button when test player loaded in browser with nopopup URL and in case of mobile app
             * Hide close button when test player loaded with chromeSecureTestingApp
             */
            scope.hideCloseButton = function() {
                return hideCloseButtonFunction(scope);
            };

            /**
             * Closes the LDB window or the Limited Lockdown window depending on which is active.
             */
            scope.closeWindow = function () {
                if (Lockdown.isCurrentWindowLockdown()) {
                    if (Lockdown.isChromiumKioskApp()) {
                        try {
                            chrome.runtime.sendMessage(
                                ChromeKioskAppID.get($window.location.host),
                                ChromeKioskApp.exitMessage
                            );
                        } catch (e) {
                            NewRelic.noticeError(e, {
                                failureType : 'windowCloseHeaderDirectiveFailedCloseWindow'
                            });
                        }
                    } else if (OS.isMac()) {
                        $window.SecureBrowser.CloseWindow();
                    }
                    else {
                        $window.external.CloseBrowser();
                    }
                } else {
                    $window.close();
                }
            };
        }
    };
}

module.exports = function (app) {
    app.constant('ChromeKioskMinVersionAsMobile', '3.1');
    app.directive('windowCloseHeader', WindowCloseHeaderDirective);
};
