function ProctorApi ($http, AppConfig) {
    'use strict';

    var basePath = AppConfig.proctorAPIHost + '/proctor';

    var routes = {
        joinTestSession:  basePath + '/joinTestSession'
    };

    /**
     * @method joinTestSession
     * @param {string}      sessionName
     * @param {string}      sessionPin
     * @param {function}    successCallback
     * @param {function}    errorCallback
     */
    var joinTestSession = function (sessionName, sessionPin, successCallback, errorCallback) {
        var requestPayload = {
            testSessionName:     sessionName,
            testSessionPassword: sessionPin
        };

        return $http.post(routes.joinTestSession, requestPayload)
            .then(successCallback, errorCallback);
    };

    return {
        joinTestSession: joinTestSession
    };
}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.factory('ProctorApi', ProctorApi);
};