function SessionQueryParams (TestPlayerSessionKeys, TestPlayerSessionService) {
    function getSummativeURLWithParams () {
        var queryParams = new URLSearchParams();

        queryParams.set(TestPlayerSessionKeys.schoolBid, TestPlayerSessionService.getSchoolBid());
        queryParams.set(TestPlayerSessionKeys.sessionName, TestPlayerSessionService.getSessionName());
        queryParams.set(TestPlayerSessionKeys.sessionPIN, TestPlayerSessionService.getSessionPIN());
        queryParams.set(TestPlayerSessionKeys.testPlayerType, TestPlayerSessionService.getTestPlayerType());
        queryParams.set(TestPlayerSessionKeys.testSessionBid, TestPlayerSessionService.getTestSessionBid());

        return "?" + queryParams.toString();
    }

    return {
        getSummativeURLWithParams: getSummativeURLWithParams
    };
}

module.exports = function (app) {
    app.factory('SessionQueryParams', SessionQueryParams);
};