var _ = require('lodash');

function UserAgentClientHints($window, $q) {

    function getRequiredClientHintsParameters(params) {
        return $window.navigator.userAgentData.getHighEntropyValues(params).then(function (userAgentData) {
            return userAgentData;
        });
    }

    return {
        clientHintsData: function (params) {
            var deferred = $q.defer();
            var clientHints = getRequiredClientHintsParameters(params);
            clientHints.then(function (UAClientHintsData) {
                var clientHintsData = {};
                _.values(params).forEach(function(param) {
                    if(UAClientHintsData.hasOwnProperty(param)) {
                        clientHintsData[param] = UAClientHintsData[param];
                    }
                });
                if (Object.keys(clientHintsData).length !== 0) {
                    deferred.resolve(clientHintsData);
                } else {
                    return deferred.reject();
                }
            });
            return deferred.promise;
        },
        clientHintsExists: function () {
            return $window.navigator.userAgentData !== undefined;
        }
    }
}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.factory('UserAgentClientHints', UserAgentClientHints);
};