function UtilitiesService () {
    'use strict';

    /* istanbul ignore next */
    /**
     * Handler for tab key
     * @param e Keyboard Event
     *
     */
    var tabKeyHandler = function (e) {
        if (e.which === 9 && /* keyCode 9 = tab */
            e.target.tabIndex > 0 && /* the routine only works for tabIndex > 0 */
            !e.isDefaultPrevented()) /* don't do anything if we've handled tabbing already */ {
            e.preventDefault();
            // Check if we're in a region where tabbing is restricted
            var ancestorWithTabbingRestricted =
              $(e.target).closest("[data-tabbing-restricted='true']"); /* ~O(log(n)) cost for n nodes in the DOM? */
            var highestTabbableAncestor =
              ancestorWithTabbingRestricted[0] !== undefined ? ancestorWithTabbingRestricted : document;
            var tabbables = $(highestTabbableAncestor).find("[tabindex]:visible")/* :tabbable doesn't quite work. ref TP 55596 */
                .filter(function(index, element) {
                    var visibility = $(element).css('visibility') === 'hidden';
                    var display = $(element).css('display') === 'none';

                    return element.tabIndex > 0 &&
                    !visibility &&
                    !display &&
                    !element.disabled;
                })
                .sort(function (a, b) {
                    return ((a.tabIndex < b.tabIndex) ? -1 : ((a.tabIndex > b.tabIndex) ? 1 :
                    (a.compareDocumentPosition(b) & 2 ? 1 : -1))); /* See https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition*/
                });
            var indexOfTarget = jquery.inArray(e.target, tabbables);
            if (indexOfTarget !== -1) {
                var indexOfNextElement = indexOfTarget + 1;
                var indexOfPrevElement = indexOfTarget - 1;
                if (indexOfPrevElement === -1) {
                    indexOfPrevElement = tabbables.length - 1;
                }
                if (indexOfNextElement === tabbables.length) {
                    indexOfNextElement = 0;
                }
                if (e.shiftKey) {
                    $(tabbables[indexOfPrevElement]).trigger('focus');
                } else {
                    $(tabbables[indexOfNextElement]).trigger('focus');
                }
            }
        }
    };

    return {
        tabKeyHandler: tabKeyHandler
    };
}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.service('UtilitiesService', UtilitiesService);
};
