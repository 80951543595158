function OS ($window) {
    /**
     * @method  isIpad
     * @returns {boolean}
     */
    this.isIpad = function () {
        var platform  = $window.navigator.platform.toLowerCase();
        var userAgent = $window.navigator.userAgent.toLowerCase();

        return userAgent.indexOf('ipad') >= 0 || platform.indexOf('ipad') >= 0;
    };

    /**
     * @method  isMac
     * @returns {boolean}
     */
    this.isMac = function () {
        var platform = $window.navigator.platform.toLowerCase();

        return platform.indexOf('mac') >= 0;
    };
}

module.exports = function (app) {
    app.service('OS', OS);
};