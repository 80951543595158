require('./legal-footer.css');

function LegalFooterDirective () {
    'use strict';

    return {
        restrict: 'E',
        template: require('./legal-footer.partial.html')
    };
}

/**
 * @param {Object} app - Angular module
 */
module.exports = function (app) {
    app.directive('legalFooter', LegalFooterDirective);
};

